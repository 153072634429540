// extracted by mini-css-extract-plugin
export var closeX = "header-module--closeX--94709";
export var extraMobileHeight = "header-module--extraMobileHeight--17e0a";
export var footerRepeatedLink = "header-module--footerRepeatedLink--3babb";
export var footerRepeatedLinks = "header-module--footerRepeatedLinks--aa71d";
export var hamburger = "header-module--hamburger--b8439";
export var header = "header-module--header--e1d40";
export var icon = "header-module--icon--4bbbd";
export var image = "header-module--image--0e6ed";
export var navContainer = "header-module--navContainer--510d6";
export var navContainerIcons = "header-module--navContainerIcons--c53e4";
export var navContainerMainTitles = "header-module--navContainerMainTitles--4f55e";
export var navLink = "header-module--navLink--96a66";
export var openMenu = "header-module--openMenu--9d443";
export var selected = "header-module--selected--7f1a3";
export var title = "header-module--title--8177a";